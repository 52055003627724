import ozon from "../../Images/ozon.png";
import voroved from "../../Images/doctor_vodoved.png";
import lechi_crasivo from "../../Images/lechi_krasivo.png";
import book_shop from "../../Images/book_shop.png";
import { functinoGetKeys } from "./returnKeys";

const baseUrl =
  "https://script.google.com/macros/s/AKfycbwXYxaTvMj_0yqKp_1_OJUrlQxAW0iw95sQGEa5Liad972hTWn4xLaaTSiU7oOr0TqJJQ/exec";

const testUrl =
  "https://script.google.com/macros/s/AKfycbw2sEJmOg_xEDsHuwBfXqcj0bwXOaA4CVRWyBB-qby-YnBu29zteKLQCaenWYseoA6prg/exec";

export const senderRow = (requestFirst, n) =>
  `${baseUrl}?action=addTask&rowIndex=${!n ? 1 : 0}&${
    requestFirst[n]?.list != undefined
      ? requestFirst[n].list.map((li) => {
          if (li?.range) {
            return `${functinoGetKeys(li.name)}=${li.range}&`;
          }
          if (li?.answer) {
            return `${functinoGetKeys(li.name)}=${li.answer}&`;
          }
          if (li?.isChecked) {
            return `${functinoGetKeys(li.name)}=${li.isChecked}&`;
          }
        })
      : requestFirst[n].answer.map(
          (ans) => `${functinoGetKeys(ans.name)}=${ans.answer}&`
        )
  }`
    .toString()
    .replace(/,/g, "")
    .replace(/%/g, "")
    .replace(/true/g, "да")
    .replace(/undefined/g, "")
    .slice(0, -1);

export const requestPersonalString = (
  firstName,
  lastName,
  middleName,
  email,
  phoneNumber,
  gift
) =>
  `${baseUrl}?action=addTask&rowIndex=0&firstName=${firstName}&lastName=${lastName}&middleName=${middleName}&email=${email}&phoneNumber=${phoneNumber}&gift=${gift}`;

export const giftArray = [
  {
    name: "ozon",
    img: ozon,
  },
  {
    name: "lechi_crasivo",
    img: lechi_crasivo,
  },
  {
    name: "doctor_voroved",
    img: voroved,
  },
  {
    name: "book_shop",
    img: book_shop,
  },
];

export const onclick = (photeNumber, setPhoneNumber, setPhoneCounter) => {
  if (!photeNumber.length) {
    setPhoneNumber("+7");
    setPhoneCounter(0);
  }
};

export const onkeydown = (
  photeNumber,
  setPhoneNumber,
  phoneCounter,
  setPhoneCounter
) => {
  let old = 0;
  var curLen = photeNumber.length;
  if (curLen < old) {
    old--;
    return;
  }
  if (curLen == 2 && curLen > phoneCounter) {
    setPhoneCounter(curLen);
    setPhoneNumber((prev) => prev + "(");
  }

  if (curLen == 6 && curLen > phoneCounter) {
    setPhoneCounter(curLen);
    setPhoneNumber((prev) => prev + ")-");
  }

  if (curLen == 11 && curLen > phoneCounter) {
    setPhoneCounter(curLen);
    setPhoneNumber((prev) => prev + "-");
  }

  if (curLen == 14 && curLen > phoneCounter) {
    setPhoneCounter(curLen);
    setPhoneNumber((prev) => prev + "-");
  }

  if (curLen > 16)
    setPhoneNumber(photeNumber.substring(0, photeNumber.length - 1));
  old++;
};

export const compileResultFunction = (answers) => {
  let array = [{ list: [] }, { answer: [] }];

  for (let i = 0; i < answers.length; i++) {
    if (
      answers[i]?.list?.find((li) => {
        return li?.isChecked || li?.range || li?.answer;
      })
    ) {
      array[0].list.push(
        ...answers[i].list.filter((li) => {
          return li?.isChecked || li?.range || li?.answer;
        })
      );
    } else if (answers[i]?.list == undefined && answers[i].answer) {
      array[1].answer.push({
        name: answers[i].blockName,
        answer: answers[i].answer,
      });
    }
  }
  return array;
};

