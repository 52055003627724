import React, { useEffect, useState } from "react";
import style from "./questionsChoiceOfOptionsMany.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerOtherVariantAction,
  chackedVariantsAction,
} from "../../Redux/AnswersReducer";

const QuestionsChoiceOfOptionsMany = ({
  currentBlock,
  currentGlobalQuestionsIndex,
}) => {
  const { answers } = useSelector((state) => state.answers);
  const dispatch = useDispatch();
  const changeFreeAnswerValue = (e) => {
    dispatch(
      addFreeAnswerOtherVariantAction(
        currentBlock.blockName,
        e.currentTarget.value
      )
    );
  };
  return (
    <section>
      <h2 className={style.head}>{currentBlock.blockName}</h2>
      <div className={style.main}>
        {currentBlock.list?.map((li, index) => {
          if (li?.answer != undefined) {
            return (
              <input
                type="text"
                placeholder="Ваш вариант ответа"
                className={style.textInput}
                key={index}
                onChange={changeFreeAnswerValue}
                value={answers[currentGlobalQuestionsIndex]?.list[index].answer}
              />
            );
          } else {
            return (
              <div
                onClick={() => {
                  dispatch(
                    chackedVariantsAction(currentBlock.blockName, li.name)
                  );
                }}
                style={
                  answers[currentGlobalQuestionsIndex]?.list[index].isChecked
                    ? { background: "#00457c" }
                    : {}
                }
                className={style.mainChildren}
                key={index}
              >
                {li.name}
                {answers[currentGlobalQuestionsIndex]?.list[index]
                  .isChecked && <span className={style.clouse}>✕</span>}
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default QuestionsChoiceOfOptionsMany;
