import React, { useEffect, useState } from "react";
import style from "./questionsChoiceOfOptionsOne.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerOtherVariantAction,
  chackedVariantAction,
  pushDontShowActionAction,
} from "../../Redux/AnswersReducer";

const QuestionsChoiceOfOptionsOne = ({
  currentBlock,
  currentGlobalQuestionsIndex,
}) => {
  const { answers, dontShow } = useSelector((state) => state.answers);
  const [opacityVariants, setOpacityVariants] = useState(false);
  const dispatch = useDispatch();

  const onChangeInput = (e) => {
    if (currentBlock.list?.find((li) => li?.answer != "")) {
      dispatch(chackedVariantAction(currentBlock.blockName));
    }
    dispatch(
      addFreeAnswerOtherVariantAction(
        currentBlock.blockName,
        e.currentTarget.value
      )
    );
  };

  return (
    <section>
      <h2 className={style.head}>{currentBlock.blockName}</h2>
      <div
        className={currentBlock.list?.length > 4 ? style.main : style.mainShort}
      >
        {currentBlock.list?.map((li, index) => {
          return (
            <div
              onClick={() => {
                if (li?.dontShow != undefined) {
                  dispatch(pushDontShowActionAction(li.dontShow));
                }
                if (
                  li?.answer == undefined &&
                  answers[currentGlobalQuestionsIndex].list?.find((li) => {
                    if (li?.answer != undefined) {
                      return li.answer.length;
                    }
                  })
                ) {
                  dispatch(
                    chackedVariantAction(currentBlock.blockName, li.name)
                  );
                }
                if (
                  li?.answer == undefined &&
                  answers[currentGlobalQuestionsIndex].list?.find((li) => {
                    if (li?.answer == undefined) {
                      return true;
                    }
                  })
                ) {
                  dispatch(
                    chackedVariantAction(currentBlock.blockName, li.name)
                  );
                  if (li?.dontShow == undefined) {
                    for (let i = 0; i < currentBlock?.list.length; i++) {
                      if (
                        dontShow.find(
                          (el) => el === currentBlock.list[i]?.dontShow
                        )
                      ) {
                        dispatch(
                          pushDontShowActionAction(
                            currentBlock.list[i]?.dontShow
                          )
                        );
                      }
                    }
                  }
                }
              }}
              style={
                answers[currentGlobalQuestionsIndex]?.list[index].isChecked
                  ? { background: "#00457c" }
                  : opacityVariants &&
                    !answers[currentGlobalQuestionsIndex]?.list[index].isChecked
                  ? {}
                  : {}
              }
              className={
                li?.answer != undefined
                  ? style.mainChildrenText
                  : style.mainChildren
              }
              key={index}
            >
              {li?.answer == undefined && li.name}
              {li?.answer != undefined && (
                <input
                  placeholder={li.name}
                  onChange={onChangeInput}
                  value={
                    answers[currentGlobalQuestionsIndex]?.list[index].answer
                  }
                />
              )}
              {answers[currentGlobalQuestionsIndex]?.list[index].isChecked &&
                !li?.answer && <span className={style.clouse}>✕</span>}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default QuestionsChoiceOfOptionsOne;
