import React, { useEffect, useRef, useState } from "react";
import style from "./questionsOnScale.module.css";
import Range from "../RangeComponent/Range";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerOtherVariantAction,
  changeQuestionScaleAction,
  checkedDontShowVariantAction,
} from "../../Redux/AnswersReducer";

const QuestionsOnScale = ({ currentBlock, currentGlobalQuestionsIndex }) => {
  const [arrayRange, setArrayRange] = useState([1, 2, 3, 4, 5]);
  const [isDontInteres, setIsDontInteres] = useState(false);
  const { answers } = useSelector((state) => state.answers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDontInteres) {
      dispatch(changeQuestionScaleAction(currentBlock.blockName));
      dispatch(addFreeAnswerOtherVariantAction(currentBlock.blockName, ""));
    }
  }, [isDontInteres]);
  const onChangeFreeAnswerValue = (e) => {
    dispatch(
      addFreeAnswerOtherVariantAction(
        currentBlock.blockName,
        e.currentTarget.value
      )
    );
  };
  const chandeInteres = () => {
    setIsDontInteres(!isDontInteres);
    dispatch(checkedDontShowVariantAction(currentBlock.blockName));
  };
  return (
    <section className={style.mainOnScale}>
      <h2 className={style.head}>{currentBlock?.blockName}</h2>
      {currentBlock?.list.map((el, index) => {
        return (
          <div style={{ color: "#94c11c" }} key={index}>
            {el.answer == undefined && el.name + ":"}
            <span style={{ display: "flex", gap: "7px", paddingTop: "4px" }}>
              {el.range ? (
                arrayRange.map((li, index) => (
                  <Range
                    key={index}
                    number={li}
                    name={el.name}
                    currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                  />
                ))
              ) : el?.answer != undefined ? (
                <input
                  className={style.textInput}
                  placeholder="Ваш вариант ответа"
                  type="text"
                  onChange={onChangeFreeAnswerValue}
                  value={
                    answers[currentGlobalQuestionsIndex]?.list[index].answer
                  }
                />
              ) : (
                <input
                  onChange={chandeInteres}
                  type="checkBox"
                  checked={
                    answers[currentGlobalQuestionsIndex]?.list[index]?.isChecked
                  }
                />
              )}
            </span>
          </div>
        );
      })}
    </section>
  );
};

export default QuestionsOnScale;
