import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  compileResultFunction,
  giftArray,
  onclick,
  onkeydown,
  requestPersonalString,
  senderRow,
} from "../Utils/utils";
import style from "./allFinalStyle.module.css";
import {
  setGiftAction,
  setPersonalDataAction,
} from "../../Redux/AnswersReducer";
import mero from "../../Images/MERO_Place.png";
import Preloader from "../preLoader/Preloader";

export const SenderComponent = () => {
  const { answers } = useSelector((state) => state.answers);
  const [next, setNext] = useState(false);
  const [sendResult, setSendResult] = useState(false);
  const requestFirst = compileResultFunction(answers);
  const [activeIndex, setActiveIndex] = useState(null);
  const { gift } = useSelector((state) => state.answers.personalData);

  return (
    <>
      {sendResult ? (
        <SendResult requestFirst={requestFirst} />
      ) : next ? (
        <SendPersonalData setSendResult={setSendResult} />
      ) : (
        <section style={{ textAlign: "center" }}>
          <h2 className={style.giftHead}>
            Спасибо за прохождение опроса! Вы стали одним из счастливчиков,
            которые примут участие в розыгрыше подарочных сертификатов.
          </h2>
          <div className={style.gifts}>
            {giftArray.map((el, index) => (
              <GiftComponent
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                key={index}
                index={index}
                name={el.name}
                img={el.img}
              />
            ))}
          </div>
          <button
            style={gift ? { background: "#94c11c" } : { background: "grey" }}
            className={style.sendButton}
            onClick={() => {
              if (gift) {
                setNext(true);
              } else {
                alert("Пожалуйста, выберете подарок!");
              }
            }}
          >
            Отправить результаты
          </button>
          <a
            style={{ textDecoration: "none" }}
            href="https://mero.place"
            target="_blank"
          >
            <div className="poweredByMero">
              <span>Сделано на платформе</span>
              <img src={mero} alt="" />
            </div>
          </a>
        </section>
      )}
    </>
  );
};

const GiftComponent = ({ img, name, index, setActiveIndex, activeIndex }) => {
  const dispatch = useDispatch();

  return (
    <section
      onClick={() => {
        if (index === activeIndex) {
          setActiveIndex(null);
          dispatch(setGiftAction(name));
        } else {
          setActiveIndex(index);
          dispatch(setGiftAction(name));
        }
      }}
      style={
        index === activeIndex
          ? { transform: "scale(1.15,1.15)", boxShadow: "0 0 10px #94c11c" }
          : {}
      }
      className={style.giftComponentBody}
    >
      <img src={img} alt={name} />
    </section>
  );
};

const SendPersonalData = ({ setSendResult }) => {
  const dispatch = useDispatch();
  const [photeNumber, setPhoneNumber] = useState("");
  const [phoneCounter, setPhoneCounter] = useState(0);
  const { firsName, lastName, middleName, email } = useSelector(
    (state) => state.answers.personalData
  );
  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.currentTarget.value);
    dispatch(setPersonalDataAction("", "", "", "", e.currentTarget.value));
  };

  const onChangeFirstName = (e) => {
    dispatch(setPersonalDataAction(e.currentTarget.value, "", "", "", ""));
  };

  const onChangeLastName = (e) => {
    dispatch(setPersonalDataAction("", e.currentTarget.value, "", "", ""));
  };
  const onChangeMiddleName = (e) => {
    dispatch(setPersonalDataAction("", "", e.currentTarget.value, "", ""));
  };

  const onChangeEmail = (e) => {
    dispatch(setPersonalDataAction("", "", "", e.currentTarget.value, ""));
  };

  return (
    <section className={style.personalDataContainer}>
      <h2>
        Чтобы мы могли прислать Вам сертификат, пожалуйста, укажите Ваши ФИО,
        email и телефон
      </h2>
      <input
        onChange={onChangeFirstName}
        placeholder="Имя"
        type="text"
        name="firstName"
      />
      <input
        onChange={onChangeLastName}
        placeholder="Фамилия"
        type="text"
        name="lastName"
      />
      <input
        onChange={onChangeMiddleName}
        placeholder="Отчечтво"
        type="text"
        name="middleName"
      />
      <input
        onChange={onChangeEmail}
        placeholder="Эл.почта"
        type="email"
        name="email"
      />
      <input
        onClick={() => {
          onclick(photeNumber, setPhoneNumber, setPhoneCounter);
        }}
        onKeyDown={() => {
          onkeydown(photeNumber, setPhoneNumber, phoneCounter, setPhoneCounter);
        }}
        value={photeNumber}
        onChange={onChangePhoneNumber}
        placeholder="Телефон"
        type="tel"
        name="phoneNumber"
      />
      <button
        style={
          firsName && lastName && middleName && email
            ? { background: "#94c11c" }
            : { background: "grey" }
        }
        onClick={() => {
          if ((!firsName, !lastName, !middleName, !email)) {
            alert("заполните пожалуйста ФИО и email");
          } else {
            setSendResult(true);
          }
        }}
        className={style.sendButton}
      >
        Отправить
      </button>
      <a
        style={{ textDecoration: "none" }}
        href="https://mero.place"
        target="_blank"
      >
        <div className="poweredByMero">
          <span>Сделано на платформе</span>
          <img src={mero} alt="" />
        </div>
      </a>
    </section>
  );
};

const SendResult = ({ requestFirst }) => {
  const [num, setNum] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const { firsName, lastName, middleName, email, phoneNumber, gift } =
    useSelector((state) => state.answers.personalData);

  useEffect(() => {
    if (num < requestFirst.length) {
      if (requestFirst[num]?.list != undefined) {
        axios
          .post(senderRow(requestFirst, num))
          .then((r) => {
            return setNum((prev) => prev + 1);
          })
          .catch((e) => console.log(e));
      } else {
        axios
          .post(senderRow(requestFirst, num))
          .then((r) => setNum((prev) => prev + 1))
          .catch((e) => console.log(e));
      }
    } else {
      axios
        .post(
          requestPersonalString(
            firsName,
            lastName,
            middleName,
            email,
            phoneNumber,
            gift
          )
        )
        .then(
          (r) => setIsSuccess(true),
          window.localStorage.setItem("isCompleted", true)
        )
        .catch((e) => console.log(e));
    }
  }, [num]);
  return (
    <>
      {!isSuccess ? (
        <div style={{ textAlign: "center" }}>
          <h2 className={style.finalH2}>
            Отправка данных займет некоторое время, пожалуйста подождите
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Preloader />
          </div>
          {num == 0 ? (
            <div>Отправляем Ваши Результаты...</div>
          ) : num == 1 ? (
            <div>Внимательно проверяем все данные...</div>
          ) : num == 2 ? (
            <div>Считаем общее колличество участников...</div>
          ) : (
            <div>Еще буквально несколько секунд...</div>
          )}
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h3>Успешно!</h3>
          Спасибо! О результатах розыгрыша подарочных сертификатов мы сообщим 23
          июля 2023 года.
        </div>
      )}
      <div className="poweredByMero">
        <span>Сделано на платформе</span>
        <img src={mero} alt="" />
      </div>
    </>
  );
};
