import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { useEffect, useState } from "react";
import { initialStateAction } from "./Redux/QuestionsReducer";
import { questionsClone } from "./data";
import { modalTypes } from "./types";
import FreeAnswer from "./components/FreeAnswer/FreeAnswer";
import QuestionsChoiceOfOptionsMany from "./components/QuestionsChoiceOfOptionsMany/QuestionsChoiceOfOptionsMany";
import QuestionsChoiceOfOptionsOne from "./components/QuestionsChoiceOfOptionsOne/QuestionsChoiceOfOptionsOne";
import QuestionsOnScale from "./components/QuestionsOnScale/QuestionsOnScale";
import { initAnswerStateAction } from "./Redux/AnswersReducer";
import { SenderComponent } from "./components/FinalComponents/SenderComponent";
import mero from "./Images/MERO_Place.png";
import { compileResultFunction } from "./components/Utils/utils";
import WelcomeComponent from "./components/WelcomeComponent/WelcomeComponent";

function App() {
  const dispatch = useDispatch();
  const [currentGlobalQuestionsIndex, setCurrentGlobalQuestionsIndex] =
    useState(0);
  const { questions } = useSelector((state) => state.questions);
  const { dontShow, answers } = useSelector((state) => state.answers);
  const [vector, setVector] = useState("next");
  const [runToFinalComponents, setRunToFinalComponents] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [isCompleted, setIsCompleted] = useState(null);
  const [finalArrayLength, setFinalArrayLength] = useState(
    compileResultFunction(answers)
  );

  useEffect(() => {
    setFinalArrayLength(compileResultFunction(answers));
  }, [answers]);

  useEffect(() => {
    if (window.localStorage.getItem("isCompleted")) {
      setIsCompleted(window.localStorage.getItem("isCompleted"));
      console.log("success");
    }
  }, []);

  useEffect(() => {
    if (
      dontShow.find((index) => index == currentGlobalQuestionsIndex + 1) &&
      vector === "next"
    ) {
      setCurrentGlobalQuestionsIndex((prev) => prev + 1);
    } else if (
      dontShow.find((index) => index == currentGlobalQuestionsIndex + 1) &&
      vector === "pred"
    ) {
      setCurrentGlobalQuestionsIndex((prev) => prev - 1);
    }
  }, [currentGlobalQuestionsIndex]);

  useEffect(() => {
    dispatch(initialStateAction(questionsClone));
    dispatch(initAnswerStateAction(structuredClone(questionsClone)));
  }, [questions]);

  return (
    <section className="App">
      {!welcome ? (
        <WelcomeComponent isCompleted={isCompleted} start={setWelcome} />
      ) : (
        <div className="mainContainer">
          {!runToFinalComponents ? (
            <>
              <div className="containerQuestions">
                {questions[currentGlobalQuestionsIndex]?.type ===
                modalTypes.freeAnswer ? (
                  <FreeAnswer
                    currentBlock={questions[currentGlobalQuestionsIndex]}
                    currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                  />
                ) : questions[currentGlobalQuestionsIndex]?.type ===
                  modalTypes.questionsChoiceOfOptionsMany ? (
                  <QuestionsChoiceOfOptionsMany
                    currentBlock={questions[currentGlobalQuestionsIndex]}
                    currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                  />
                ) : questions[currentGlobalQuestionsIndex]?.type ===
                  modalTypes.questionsChoiceOfOptionsOne ? (
                  <QuestionsChoiceOfOptionsOne
                    currentBlock={questions[currentGlobalQuestionsIndex]}
                    currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                  />
                ) : (
                  <QuestionsOnScale
                    currentBlock={questions[currentGlobalQuestionsIndex]}
                    currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
                  />
                )}
              </div>
              <div className="nextOrPrevConsole">
                <span
                  onClick={() => {
                    if (currentGlobalQuestionsIndex > 0) {
                      setVector("pred");
                      setCurrentGlobalQuestionsIndex((prev) => prev - 1);
                    }
                  }}
                >
                  ⟨
                </span>
                <div className="pagination">
                  {questions.map((el, index) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrentGlobalQuestionsIndex(index);
                        }}
                        key={index}
                        style={
                          dontShow.find((number) => number === index + 1) ||
                          (answers[index]?.list?.find(
                            (li) => li?.range || li?.answer || li?.isChecked
                          ) &&
                            index != currentGlobalQuestionsIndex) ||
                          (answers[index]?.answer &&
                            index != currentGlobalQuestionsIndex)
                            ? { background: "green" }
                            : index === currentGlobalQuestionsIndex
                            ? { background: "white" }
                            : {}
                        }
                      ></div>
                    );
                  })}
                </div>
                {currentGlobalQuestionsIndex < questions.length - 1 ? (
                  <span
                    onClick={() => {
                      if (currentGlobalQuestionsIndex != questions.length - 1) {
                        setVector("next");
                        setCurrentGlobalQuestionsIndex((prev) => prev + 1);
                      }
                    }}
                  >
                    ⟩
                  </span>
                ) : (
                  <button
                    style={
                      finalArrayLength[0].list.length ||
                      finalArrayLength[1].answer.length
                        ? { background: "#94c11c" }
                        : { background: "grey" }
                    }
                    className="finishButton"
                    onClick={() => {
                      if (
                        finalArrayLength[0].list.length ||
                        finalArrayLength[1].answer.length
                      ) {
                        setRunToFinalComponents(true);
                      } else {
                        alert("Анкета не может быть совсем пустой)");
                      }
                    }}
                  >
                    Далее
                  </button>
                )}
              </div>
              <a
                style={{ textDecoration: "none" }}
                href="https://mero.place"
                target="_blank"
              >
                <div className="poweredByMero">
                  <span>Сделано на платформе</span>
                  <img src={mero} alt="" />
                </div>
              </a>
            </>
          ) : (
            <SenderComponent />
          )}
        </div>
      )}
    </section>
  );
}

export default App;
