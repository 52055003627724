export const keys = {
  key_1: "Диагностика инфекций мочевыделительной системы",
  key_2: "Терапия и ведение пациентов с инфекциями мочевыделительной системы",
  key_3: "Клинические рекомендации",
  key_4: "Клинические исследования",
  key_5: "Анонсы мероприятий и встреч с экспертами",
  key_6: "Новости и научные публикации",
  key_7: "Разбор клинических случаев",
  key_8: "Другое (ваши варианты ответа.)",
  key_9: "Не интересны темы, посвященные инфекциям почек и мочевых путей",
  key_10: "Медвестник",
  key_11: "Русский Медицинский Журнал (РМЖ)",
  key_12: "Uroweb",
  key_13: "Доктор на работе",
  key_14: "Лечащий врач",
  key_15: "Не использую такие сайты",
  key_16: "Другое (ваши варианты ответа:)",
  key_17: "Да, какие именно:",
  key_18: "Нет, такие сайты мне не интересны",
  key_19: "Нет, но такой сайт был бы мне интересен",
  key_20: "Урологи (https://vk.com/urology_1)",
  key_21: "Урология | Андрология | Консультации (https://vk.com/uro_andromed)",
  key_22: "Комьюнити в Tg «Уродинаторская»",
  key_23: "Медицина (https://vk.com/medrussia)",
  key_24: "Provrach. Будни медицины (https://t.me/provrach)",
  key_25: "Не использую такие группы",
  key_26: "Другое (ваши варианты ответа/)",
  key_27:
    "Какие препараты, помимо антибиотиков, Вы назначаете пациентам с инфекциями почек и мочевых путей? Перечислите все, что вспомните, включая названия брендов и действующих веществ?",
  key_28: "По каким критериям Вы выбираете эти препараты?",
  key_29: "менее 25%:",
  key_30: "менее 50%:",
  key_31: "менее 75%:",
  key_32: "более 75%:",
  key_33: "не назначаю Канефрон.",
  key_34: "менее 25%",
  key_35: "менее 50%",
  key_36: "менее 75%",
  key_37: "более 75%",
  key_38: "не назначаю Канефрон",
  key_39:
    "Что не позволяет назначать Канефрон чаще при инфекциях почек и мочевых путей?",
  key_40: "Что не позволяет назначать Канефрон чаще при мочекаменной болезни?",
  key_41: "Медицинские квизы (викторины)",
  key_42: "Деловые ужины с коллегами",
  key_43: "Экскурсии",
  key_44: "Квесты",
  key_45: "Театры",
  key_46: "Ваши варианты ответа",
};

export const functinoGetKeys = (value) => {
  if (value === keys.key_1) return "B";
  if (value === keys.key_2) return "C";
  if (value === keys.key_3) return "D";
  if (value === keys.key_4) return "E";
  if (value === keys.key_5) return "F";
  if (value === keys.key_6) return "G";
  if (value === keys.key_7) return "H";
  if (value === keys.key_8) return "I";
  if (value === keys.key_9) return "J";
  if (value === keys.key_10) return "K";
  if (value === keys.key_11) return "L";
  if (value === keys.key_12) return "M";
  if (value === keys.key_13) return "N";
  if (value === keys.key_14) return "O";
  if (value === keys.key_15) return "P";
  if (value === keys.key_16) return "Q";
  if (value === keys.key_17) return "R";
  if (value === keys.key_18) return "S";
  if (value === keys.key_19) return "T";
  if (value === keys.key_20) return "U";
  if (value === keys.key_21) return "V";
  if (value === keys.key_22) return "W";
  if (value === keys.key_23) return "X";
  if (value === keys.key_24) return "Y";
  if (value === keys.key_25) return "Z";
  if (value === keys.key_26) return "AA";
  if (value === keys.key_27) return "AB";
  if (value === keys.key_28) return "AC";
  if (value === keys.key_29) return "AD";
  if (value === keys.key_30) return "AE";
  if (value === keys.key_31) return "AF";
  if (value === keys.key_32) return "AG";
  if (value === keys.key_33) return "AH";
  if (value === keys.key_34) return "AI";
  if (value === keys.key_35) return "AJ";
  if (value === keys.key_36) return "AK";
  if (value === keys.key_37) return "AL";
  if (value === keys.key_38) return "AM";
  if (value === keys.key_39) return "AN";
  if (value === keys.key_40) return "AO";
  if (value === keys.key_41) return "AP";
  if (value === keys.key_42) return "AQ";
  if (value === keys.key_43) return "AR";
  if (value === keys.key_44) return "AS";
  if (value === keys.key_45) return "AT";
  if (value === keys.key_46) return "AU";
};
